/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'

const btnIconClass = 'fs-5 text-muted'
const languages = [
  {
    lang: 'nl',
    name: 'Dutch',
    flag: toAbsoluteUrl('/media/flags/netherlands.svg'),
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'fr',
    name: 'French',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
]
const userAvatarClass = 'symbol-35px'

const HeaderLanguages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <>
    <div
        className={clsx('cursor-pointer symbol', userAvatarClass)}
        data-kt-menu-trigger="{default: 'click'}"
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
      >
      <span className={btnIconClass}>
          {currentLanguage?.name}{' '}
          <img
            className='w-15px h-15px rounded-1 ms-2'
            src={currentLanguage?.flag}
            alt='metronic'
          />
        </span>
    </div>
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-175px'
      data-kt-menu='true'
    >

      <div
        className='menu-item px-5'
      >
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
    </>
  )
}

export {HeaderLanguages}
