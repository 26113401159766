import { useIntl } from "react-intl";

export function PolicyPage() {
    const intl = useIntl();

    const message = intl.formatMessage({ id: 'PRIVACY.POLICY.BODY' });

    const replacedMessage = () => {
        const html = message;
        return { __html: html };
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <div className="mb-18">
                    <div className="mb-10">
                        <div className="text-center mb-15">
                            <h3 className="fs-2hx text-dark mb-5">{intl.formatMessage({id: 'PAGE.HEADER.PRIVACY.POLICY'})}</h3>
                        </div>
                    </div>
                    <div className="fs-5 fw-semibold text-gray-600">
                        <p className="mb-8" dangerouslySetInnerHTML={replacedMessage()} />
                    </div>
                </div>
            </div>
        </div>
    )
}