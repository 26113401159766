import axios from 'axios';

const postFijbSyndicMessage = async (data) => {
    const API_URL = process.env.REACT_APP_URL;
    try {
        const response = await axios.post(API_URL + 'fijb/syndic', data);
        if (!response.data) {
            throw new Error('Failed to fetch messages');
        }
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch messages: ' + (error as Error).message);
    }
};

export { postFijbSyndicMessage };