import * as Yup from 'yup'

export interface ICreateContactUs {
  subject: string
  firstName: string
  lastName: string
  gsm: string
  email: string
  street: string
  houseNumber: string
  message: string
  sent: boolean
}

const createContactUsSchemas = [
  Yup.object({
    subject: Yup.string().required().label('Subject'),
    firstName: Yup.string().required().label('First name'),
    lastName: Yup.string().required().label('Last name'),
    gsm: Yup.string().required().matches(
      /^\+(?:[0-9] ?){9,14}[0-9]$/,
      'Invalid phone number format. Please use the international format like +1234567890.'
    ).label('GSM'),
    email: Yup.string()
      .email("Invalid email")
      .required().label('Email'),
    street: Yup.string().required().label('Street'),
    houseNumber: Yup.string().required().label('House number'),
    message: Yup.string().required().label('Message'),
  })
]

const inits: ICreateContactUs = {
  subject: "",
  firstName: "",
  lastName: "",
  gsm: "",
  email: "",
  street: "",
  houseNumber: "",
  message: "",
  sent: false,
}

export {createContactUsSchemas, inits}
