import * as Yup from 'yup'

export interface ICreateContactSyndic {
  firstName: string
  lastName: string
  contactPhone: string
  gsm: string
  email: string
  message: string
  sent: boolean
}

const createContactSyndicSchemas = [
  Yup.object({
    firstName: Yup.string().required().label('First name'),
    lastName: Yup.string().required().label('Last name'),
    contactPhone: Yup.string().matches(
      /^\+(?:[0-9] ?){6,14}[0-9]$/,
      'Invalid phone number format. Please use the international format like +1234567890.'
    ).label('Phone'),
    gsm: Yup.string().required().matches(
      /^\+(?:[0-9] ?){9,14}[0-9]$/,
      'Invalid phone number format. Please use the international format like +1234567890.'
    ).label('GSM'),
    email: Yup.string()
      .email("Invalid email")
      .required().label('Email'),
    message: Yup.string().required().label('Message'),
  })
]

const inits: ICreateContactSyndic = {
  firstName: "",
  lastName: "",
  contactPhone: "",
  gsm: "",
  email: "",
  message: "",
  sent: false,
}

export {createContactSyndicSchemas, inits}
