import { ErrorMessage, Field, Formik, Form, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { KTIcon } from "../../_metronic/helpers";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { InquiryContactUs } from "../modules/modals/inquiry-contact-us/InquiryContactUs";
import { ICreateContactSyndic, createContactSyndicSchemas, inits } from "../modules/contact-syndic/components/CreateContactSyndicHelper";
import { postFijbSyndicMessage } from "../services/fijb_syndic_message.services";

interface LocationState {
    addressData?: string;
  }

export function ContactSyndicPage() {
    const intl = useIntl();

    //detects if the redirection contains address data if not redirect back to appointment
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const addressData = (state as LocationState)?.addressData;

    const [currentSchema] = useState(createContactSyndicSchemas[0])
    const [initValues] = useState<ICreateContactSyndic>(inits)
    const [showModal, setShowModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!addressData) {
            navigate('/appointment');
        }
    }, [addressData, navigate]);

    const onSubmit = async (values: ICreateContactSyndic, actions: FormikValues) => {
        setLoading(true);
        try {
            await handleContactSyndic(values);
            if (values.sent === true) {
                setShowModal(true);
                setIsSuccess(true);
                actions.resetForm();
                values.sent = false;
            } else {
                setShowModal(true);
                setIsSuccess(false);
            }
            setLoading(false);
        } catch (error) {
            // Handle errors here
            setLoading(false);
            console.error(error);
        }
    }

    const handleContactSyndic = async (values) => {
        if (addressData) {
            const address = JSON.parse(addressData);
            const queryParams = {
                email: values.email,
                first_name: values.firstName,
                phone: values.contactPhone,
                cellphone: values.gsm,
                last_name: values.lastName,
                message: values.message,
                survey_status_id: address.survey_status_id,
                scopelist_lu_id: address.lu_id,
            };
            try {
            const response = await postFijbSyndicMessage(queryParams);
            if (response.subject === values.subject) {
                values.sent = true;
            }
            } catch (error) {
            console.error('Error fetching data:', error);
            }
        }
    }

    const onCloseRedirect = () => {
        setShowModal(false);
        if (isSuccess) {
            navigate('/appointment');
        }
    }

    if (!addressData) {
        return null;
    }
    return (
        <>
            <InquiryContactUs showModal={showModal} isSuccess={isSuccess} onClose={() => onCloseRedirect()} />
            <div className='card'>
                <div className='card-body'>
                    <Formik
                        initialValues={initValues}
                        validationSchema={currentSchema}
                        onSubmit={onSubmit}
                    >
                    {({ values, setFieldValue }) => (
                    <Form className='row'>
                        <div className='col-2'>
                        </div>
                        <div className='col-8'>
                            <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
                                <KTIcon iconName='pencil' className='fs-2x' />
                                <div className="d-flex flex-column">
                                    <span>{intl.formatMessage({id: 'CONTACT.SYNDIC.INFO'})}</span>
                                </div>
                            </div>
                            <div className='w-100 center'>
                                <div className='pb-10 pb-lg-12'>
                                    <h2 className='fw-bolder text-dark text-center'>{intl.formatMessage({id: 'CONTACT.SYNDIC.HEADER'})}</h2>
                                </div>

                                <div className='row mb-5'>
                                    <div className='col-md-6 fv-row'>
                                        <label className='form-label required'>{intl.formatMessage({id: 'APPOINTMENT.DETAIL.FIRST.NAME'})}</label>
                                        <Field name='firstName' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='firstName'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {intl.formatMessage({id: 'ERROR.FIRST.NAME.REQUIRED'})}
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                    <div className='col-md-6 fv-row'>
                                        <label className='form-label required'>{intl.formatMessage({id: 'APPOINTMENT.DETAIL.LAST.NAME'})}</label>
                                        <Field name='lastName' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='lastName'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {intl.formatMessage({id: 'ERROR.LAST.NAME.REQUIRED'})}
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-5'>
                                    <div className='col-md-4 fv-row'>
                                        <label className='form-label'>{intl.formatMessage({id: 'APPOINTMENT.DETAIL.CONTACT.PHONE'})}</label>
                                        <Field name='contactPhone' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='contactPhone'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {msg && msg.includes('Invalid phone number format') ?
                                                    intl.formatMessage({ id: 'ERROR.CONTACT.PHONE.INVALID.FORMAT' })
                                                : ''
                                                }
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                    <div className='col-md-4 fv-row'>
                                        <label className='form-label required'>{intl.formatMessage({id: 'APPOINTMENT.DETAIL.CONTACT.MOBILE'})}</label>
                                        <Field name='gsm' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='gsm'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {msg && msg.includes('Invalid phone number format') ?
                                                    intl.formatMessage({ id: 'ERROR.CONTACT.PHONE.INVALID.FORMAT' })
                                                : intl.formatMessage({ id: 'ERROR.CONTACT.PHONE.REQUIRED' })
                                                }
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>

                                    <div className='col-md-4 fv-row'>
                                        <label className='fs-6 fw-bold form-label required'>{intl.formatMessage({id: 'APPOINTMENT.DETAIL.CONTACT.EMAIL'})}</label>
                                        <Field name='email' className='form-control form-control-lg form-control-solid' />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='email'>
                                            {(msg) => (
                                                <div style={{ color: 'red' }}>
                                                {intl.formatMessage({id: 'ERROR.CONTACT.EMAIL.REQUIRED'})}
                                                </div>
                                            )}
                                            </ErrorMessage>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-5'>
                                    <label className='fs-6 fw-bold form-label required'>{intl.formatMessage({id: 'CONTACT.US.MESSAGE'})}</label>
                                    <Field
                                        as="textarea"
                                        name='message'
                                        className='form-control form-control-lg form-control-solid'
                                        rows={3}

                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='message'>
                                        {(msg) => (
                                            <div style={{ color: 'red' }}>
                                            {intl.formatMessage({id: 'ERROR.MESSAGE.REQUIRED'})}
                                            </div>
                                        )}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                <div className='row mb-5'>
                                    <div className='d-flex justify-content-end pt-7'>
                                        <button type="submit" className='btn btn-sm fw-bolder btn-primary' disabled={loading}>
                                            {!loading && intl.formatMessage({id: 'BUTTON.SEND'})}
                                            {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                {intl.formatMessage({id: 'BUTTON.PLEASE.WAIT'})}{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    )}
                    </Formik>
                </div>
            </div>
        </>
    );
}