import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    const hasAccepted = localStorage.getItem('cookiePolicyAccepted');
    if (hasAccepted) {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, []);

  const acceptCookiePolicy = () => {
    setAccepted(true);
    localStorage.setItem('cookiePolicyAccepted', 'true');
  };

    return (
        <>
            { !accepted &&
                <>
                <div
                    className='modal fade show d-block'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='true'
                >
                    <div className='modal-dialog modal-dialog-centered mw-650px'>
                    <div className='modal-content'>
                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                            This website uses cookies. By using our website, you agree to our use of cookies.
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-light-primary' onClick={acceptCookiePolicy}>
                            Accept
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className='modal-backdrop fade show'></div>
                </>
            }
        </>
    );

};

export default CookieBanner;