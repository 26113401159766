import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helpers'
import { useIntl } from 'react-intl'
import { HeaderLanguages } from '../../../partials/layout/header-menus/HeaderLanguages'

const itemClass = 'ms-1 ms-md-4'

const Navbar = () => {

  const intl = useIntl()
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <div
          className='d-flex align-items-center'
        >
          <h3
            className='fs-5 text-muted m-0 pt-5 pb-5'
            data-kt-search-element='category-title'
          >
            {intl.formatMessage({id: 'HEADER.OFFICIAL.CONTRACTOR'})}
          </h3>
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
          <a href="http://solutions30.be/" target="_blank" rel="noopener noreferrer">
            <>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/SOLUTIONS30_gmbh_logo_weiss_neu.png')}
                className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
              />
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/SOLUTIONS30_gmbh_logo_weiss_neu.png')}
                className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
              />
            </>
          </a>
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <HeaderLanguages />
      </div>
    </div>
  )
}

export {Navbar}
